<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-title>Content Preview</b-card-title>
            <b-card-sub-title>Below is the overview of the content of file you have selected.</b-card-sub-title>

            <b-row class="mt-4">
                <b-col sm="12" md="3" lg="3">
                    <b-button v-b-popover.hover.right="'Toogle to show/hide filter options'" v-b-toggle.collapse-1
                        class="filter">
                        FILTER OPTIONS
                    </b-button>
                </b-col>
                <b-col sm="12">
                    <!-- Collapsible Filter Options -->
                    <b-collapse id="collapse-1" class="mt-2">
                        <b-card>
                            <b-row no-gutters>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Asset Code">
                                        <b-form-input id="assetCode" name="Asset Code" type="search"
                                            v-model="filterBy.assetCode" />
                                        <span v-show="errors.has('Asset Code')" class="help-block">{{
                                            errors.first('Asset Code')
                                        }}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Branch Code">
                                        <b-form-input id="branchCode" name="Branch Code" type="search"
                                            v-model="filterBy.branchCode" />
                                        <span v-show="errors.has('Branch Code')" class="help-block">{{
                                            errors.first('Branch Code')
                                        }}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Employee No">
                                        <b-form-input id="employeeNo" name="Employee No" type="search"
                                            v-model="filterBy.employeeNo" />
                                        <span v-show="errors.has('Employee No')" class="help-block">{{
                                            errors.first('Employee No')
                                        }}</span>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="6" sm="12" class="mr-4">
                                    <b-form-group label="Status">
                                        <v-select class="style-chooser" label="text" :options="statusOptions"
                                            :reduce="(status) => status.value" v-model="filterBy.status">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching">
                                                    No results found for
                                                    <em>
                                                        <strong>{{ search }}</strong>
                                                    </em>
                                                </template>
                                                <em :style="{ opacity: 0.5 }" v-else>
                                                    Start typing to search for status
                                                </em>
                                            </template>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row no-gutters>
                                <b-col sm="12">
                                    <b-button class="mr-1" variant="success" @click="onFilter">
                                        Search
                                    </b-button>
                                    <b-button class="mr-1" variant="primary" @click="resetFilter">
                                        Reset
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-collapse>
                </b-col>
            </b-row>

            <b-row class="mt-4 mb-2">
                <b-col sm="6" md="3">
                    <b-dropdown text=" Select Actions " variant="dark" slot="append">
                        <b-dropdown-item>
                            <json-excel :data="exportData" :fields="exportFields" type="xls"
                                :name="fileName + '.xls'">Export Asset Recon Validation in Excel</json-excel>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <json-excel :data="exportData" :fields="exportFields" type="csv"
                                :name="fileName + '.csv'">Export Asset Recon Validation to CSV</json-excel>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
                    <b-input-group prepend="Show" append="/ Page">
                        <b-form-select :options="pageOptions" v-model="perPage" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" responsive>

                <template v-slot:cell(assetCode)="row">
                    <span v-if="row.item.isInvalidAssetCode" class="invalid-format">
                        <b-icon icon="exclamation-circle" />
                        {{ row.item.assetCode }}
                    </span>
                    <span v-else>
                        {{ row.item.assetCode }}
                    </span>
                </template>

                <template v-slot:cell(branchCode)="row">
                    <span v-if="row.item.isInvalidBranchCode" class="invalid-format">
                        <b-icon icon="exclamation-circle" />
                        {{ row.item.branchCode }}
                    </span>
                    <span v-else>
                        {{ row.item.branchCode }}
                    </span>
                </template>

                <template v-slot:cell(employeeNo)="row">
                    <span v-if="row.item.isInvalidEmployeeNo" class="invalid-format">
                        <b-icon icon="exclamation-circle" />
                        {{ row.item.employeeNo }}
                    </span>
                    <span v-else>
                        {{ row.item.employeeNo }}
                    </span>
                </template>

                <template v-slot:cell(status)="row">
                    <span v-if="row.item.status === 'VALID'">
                        <b-badge variant="success">{{ row.item.status }}</b-badge>
                    </span>
                    <span v-else>
                        <b-badge variant="danger">{{ row.item.status }}</b-badge>
                    </span>
                </template>
            </b-table>

            <b-row>
                <b-col md="8" sm="12" class="my-1">
                    <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                </b-col>
                <b-col md="4" sm="12" class="my-1">
                    <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                        class="my-0" />
                </b-col>
            </b-row>

            <b-row class="mt-4 mb-2">
                <b-col sm="12" class="text-sm-right">
                    <b-button variant="primary" @click="onStartRecon()">
                        Start Recon
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
    name: 'asset-recon-content-preview',
    components: {
        JsonExcel
    },
    props: {
        assets: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [
                'assetCode',
                'branchCode',
                'employeeNo',
                'description',
                'status'
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            defaultFilterBy: {
                assetCode: '',
                branchCode: '',
                employeeNo: '',
                status: '',
            },
            filterBy: {
                assetCode: '',
                branchCode: '',
                employeeNo: '',
                status: '',
            },
            statusOptions: [
                { text: ' - Please select - ', value: '' },
                { text: 'VALID', value: "VALID" },
                { text: 'INVALID', value: "INVALID" },
            ],

            allCompaniesObj: {},
            allUsersObj: {}
        }
    },
    watch: {
        "assets": function (newVal, oldVal) {
            if (_.isEmpty(oldVal) && !_.isEmpty(newVal)) {
                this.items = newVal;
                this.totalRows = _.size(this.items);
            }
        },
    },
    computed: {
        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            _.forEach(this.items, item => {
                if (item.branchCode && item.branchCode.length > 0) {
                    item['Branch Code'] = "'" + item.branchCode;
                } else {
                    item['Branch Code'] = "";
                }
            });
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Asset Code': 'assetCode',
                'Is Invalid Asset Code?': 'isInvalidAssetCode',
                'Branch Code': 'Branch Code',
                'Is Invalid Branch Code?': 'isInvalidBranchCode',
                'Employee No': 'employeeNo',
                'Is Invalid Employee No?': 'isInvalidEmployeeNo',
                'Status': 'status',
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return 'AssetRecon_Validation_' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
    },
    mounted() {
        setTimeout(() => {
            try {
                // Don't initiate data retrieval when the account is not authenticated
                if (!this.$store.getters.isAuthenticated) {
                    return;
                }

                this.allCompaniesObj = this.$store.getters.companies;
                this.allUsersObj = this.$store.getters.users;

            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            }
        }, config.timeout);
    },
    methods: {
        resetFilter() {
            this.filterBy = { ...this.defaultFilterBy };
            this.onFilter();
        },
        onFilter() {
            this.items = _.filter(this.assets, o => {
                let isIncluded = true;

                if (this.filterBy.assetCode && this.filterBy.assetCode.length > 0 && !_.includes(o.assetCode, this.filterBy.assetCode)) {
                    isIncluded = false;
                }
                if (this.filterBy.branchCode && this.filterBy.branchCode.length > 0 && !_.includes(o.branchCode, this.filterBy.branchCode)) {
                    isIncluded = false;
                }
                if (this.filterBy.employeeNo && this.filterBy.employeeNo.length > 0 && !_.includes(o.employeeNo, this.filterBy.employeeNo)) {
                    isIncluded = false;
                }
                if (this.filterBy.status.length > 0 && this.filterBy.status !== o.status) {
                    isIncluded = false;
                }

                return isIncluded;
            });
            this.totalRows = _.size(this.items);
        },
        onStartRecon() {
            this.$emit('onStartRecon');
        }
    }
}
</script>

<style scoped>
.invalid-format {
    color: #f44335;
}
</style>